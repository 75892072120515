<template>
  <Card>
    <hr />
    <b-row>
      <b-col>
        <FormSelect
          v-model="model.segment_id"
          :label="'Segmento'"
          :ready="ready"
          :selectOptions="segmentOptions"
          :error="errors.includes('model.segment_id')"
        />
      </b-col>
      <b-col>
        <FormSelect
          v-model="model.matrix"
          :label="'Matrix'"
          :error="errors.includes('model.matrix')"
          :ready="ready"
          :selectOptions="[
            {
              text: 'Sim',
              value: 1,
            },
            {
              text: 'Não',
              value: 0,
            },
          ]"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="7">
        <FormInput
          :error="errors.includes('model.company_name')"
          v-model="model.company_name"
          :label="'Razão Social'"
          :ready="ready"
        />
      </b-col>
      <b-col>
        <FormInput
          v-model="model.cnpj"
          :label="'CPF/CNPJ'"
          :ready="ready"
          :error="errors.includes('model.cnpj')"
          
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="8">
        <FormInput
          v-model="model.commercial_name"
          :error="errors.includes('model.commercial_name')"
          :label="'Nome Fantasia'"
          :ready="ready"
        />
      </b-col>
      <b-col>
        <FormInput
          v-model="model.phone"
          :error="errors.includes('model.phone')"
          :label="'Telefone'"
          :ready="ready"
          :mask="'(##) #####-####'"
        />
      </b-col>
      <b-col>
        <FormInput
          v-model="model.contact_name"
          :error="errors.includes('model.contact')"
          :label="'Nome do Contato'"
          :ready="ready"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <FormInput
          :label="'CEP'"
          v-model="model.code"
          :error="errors.includes('model.code')"
          :mask="'##.###-###'"
          :ready="ready"
        />
      </b-col>
      <b-col md="6">
        <FormInput
          v-model="model.street"
          :error="errors.includes('model.street')"
          :label="'Rua'"
          :ready="ready"
        />
      </b-col>
      <b-col>
        <FormInput
          v-model="model.number"
          :error="errors.includes('model.number')"
          :label="'Numero'"
          :ready="ready"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <FormInput
          v-model="model.complement"
          :label="'Complemento'"
          :ready="ready"
        />
      </b-col>
      <b-col md="4">
        <FormInput
          v-model="model.district"
          :error="errors.includes('model.district')"
          :label="'Bairro'"
          :ready="ready"
        />
      </b-col>
      <b-col md="4">
        <FormInput
          v-model="model.city"
          :error="errors.includes('model.city')"
          :label="'Cidade'"
          :ready="ready"
        />
      </b-col>
      <b-col>
        <FormSelect
          v-model="model.state"
          :error="errors.includes('model.state')"
          :label="'Estado'"
          :ready="ready"
          :selectOptions="states"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <FormInput
          v-model="model.email"
          :error="errors.includes('model.email')"
          :label="'Email'"
          :ready="ready"
        />
      </b-col>
    </b-row>
    <hr />
    <div class="d-flex justify-content-end mb-3">
      <b-button @click="save_unity">Salvar</b-button>
    </div>
  </Card>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      ready: false,
      errors: [],
      required: [
        "model.segment_id",
        "model.matrix",
        "model.commercial_name",
        "model.company_name",
        "model.cnpj",
        "model.phone",
        "model.contact_name",
        "model.street",
        "model.number",
        "model.code",
        "model.district",
        "model.city",
        "model.state",
        "model.email",
      ],
      states: [],
      model: {
        application_id: "f504361b-f730-4748-ba63-c6dc4a2b94ee",
        commercial_name: "",
        company_name: "",
        matrix: "1",
        commercial_name_unity: "",
        company_name_unity: "",
        segment_id: "",
        contact_name: "",
        phone: "",
        email: "",
        cnpj: "",
        state: "",
        city: "",
        code: "",
        district: "",
        street: "",
        number: "",
        complement: "",
        user_id: this.$store.state.auth.currentUser.id,
      },
    };
  },
  watch: {
    "model.code": {
      async handler(v) {
        let temp = JSON.stringify(v);
        let address = "";

        if (String(v).length == 10) {
          temp = temp.replace(/\D/g, "");

          console.log(temp);

          const response = await axios.get(
            `https://viacep.com.br/ws/${temp}/json/`
          );
          console.log(response);
          if (!response.data.erro) {
            this.model.street = response.data.logradouro;
            this.model.district = response.data.bairro;
            this.model.city = response.data.localidade;
            this.model.state = response.data.uf;
          }
        }
        return address;
      },
    },
  },
  computed: {
    segmentOptions() {
      const segments = this.$store.state.unities.segment;
      return _.map(segments, (item) => {
        return { value: item.id, text: item.description };
      });
    },
  },
  methods: {
    async save_unity() {
      this.model.commercial_name_unity = this.model.commercial_name;
      this.model.company_name_unity = this.model.company_name;
      this.check_required(this.required);
      if(this.errors.length == 0){
        await this.$store.dispatch("http_post", {
          url: "/unity",
          data: this.model,
          redirect: "/empresas",
        });
      }      
    },
    async get_segment() {
      await this.$store.dispatch("http_get", {
        url: "/segment",
        vuex: "unities/segment",
      });
    },
  },
  async mounted() {
    await this.get_segment();
    this.states = await this.$store.dispatch("get_states");

    this.ready = true;
  },
};
</script>

<style>
</style>